import { svg } from "d3-fetch";
import React, { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import { data } from "./data";

const App = () => {

  const [gender, setGender] = useState(null);
  const [age, setAge] = useState(null);
  const [ards, setArds] = useState(null);
  const [outcome, setOutcome] = useState(null);

  const [trach, setTrach] = useState(false);
  const [peep, setPeep] = useState(false);
  const [nmb, setNmb] = useState(false);
  const [prone, setProne] = useState(false);
  const [nitric, setNitric] = useState(false);
  const [prostacyclin, setProstacyclin] = useState(false);
  const [broncho, setBroncho] = useState(false);
  const [rrt, setRrt] = useState(false);
  const [diuretics, setDiuretics] = useState(false);
  const [cortico, setCortico] = useState(false);
  const [heparin, setHeparin] = useState(false);
  const [remdesivir, setRemdesivir] = useState(false);
  const [azithromycin, setAzithromycin] = useState(false);
  const [hydro, setHydro] = useState(false);
  const [tocilizumab, setTocilizumab] = useState(false);
  const [anankinra, setAnankinra] = useState(false);
  const [antibac, setAntibac] = useState(false);
  const [antifung, setAntifung] = useState(false);
  const [hoveredTherapy, setHoveredTherapy] = useState(null);

  useEffect(() => {
    ReactTooltip.rebuild()
  })

  let actualData = gender ? data.filter(p => p.gender === gender) : data;
  actualData = age ? actualData.filter(p => p.age === age) : actualData;
  actualData = ards ? actualData.filter(p => p.ARDS === ards) : actualData;
  actualData = outcome ? actualData.filter(p => p.outcome === outcome) : actualData;

  actualData = trach ? actualData.filter(p => p.therapies.map(t => t.therapy).includes("Tracheostomy")) : actualData;
  actualData = peep ? actualData.filter(p => p.therapies.map(t => t.therapy).includes("PEEP>10 cmH_2O")) : actualData;
  actualData = nmb ? actualData.filter(p => p.therapies.map(t => t.therapy).includes("Neuro-muscular blockade")) : actualData;
  actualData = prone ? actualData.filter(p => p.therapies.map(t => t.therapy).includes("Prone positioning")) : actualData;
  actualData = nitric ? actualData.filter(p => p.therapies.map(t => t.therapy).includes("Inhaled nitric oxide")) : actualData;
  actualData = prostacyclin ? actualData.filter(p => p.therapies.map(t => t.therapy).includes("Inhaled prostacyclin")) : actualData;
  actualData = broncho ? actualData.filter(p => p.therapies.map(t => t.therapy).includes("Bronchoscopy")) : actualData;
  actualData = rrt ? actualData.filter(p => p.therapies.map(t => t.therapy).includes("Renal replacement therapy")) : actualData;
  actualData = diuretics ? actualData.filter(p => p.therapies.map(t => t.therapy).includes("Diuretics")) : actualData;
  actualData = cortico ? actualData.filter(p => p.therapies.map(t => t.therapy).includes("Corticosteroids")) : actualData;
  actualData = heparin ? actualData.filter(p => p.therapies.map(t => t.therapy).includes("Therapeutic heparin")) : actualData;
  actualData = remdesivir ? actualData.filter(p => p.therapies.map(t => t.therapy).includes("Remdesivir")) : actualData;
  actualData = azithromycin ? actualData.filter(p => p.therapies.map(t => t.therapy).includes("Azithromycin")) : actualData;
  actualData = hydro ? actualData.filter(p => p.therapies.map(t => t.therapy).includes("Hydroxychloroquine")) : actualData;
  actualData = tocilizumab ? actualData.filter(p => p.therapies.map(t => t.therapy).includes("Tocilizumab")) : actualData;
  actualData = anankinra ? actualData.filter(p => p.therapies.map(t => t.therapy).includes("Anankinra")) : actualData;
  actualData = antibac ? actualData.filter(p => p.therapies.map(t => t.therapy).includes("Anti-Bacterial")) : actualData;
  actualData = antifung ? actualData.filter(p => p.therapies.map(t => t.therapy).includes("Anti-Fungal")) : actualData;

  const paths = actualData.map(patient => patient.therapies.map(t => t.therapy).concat([patient.outcome]));

  const connections = []; // This contains the final data for the Sankey
  for (let path of paths) {
    for (let x = 0; x < path.length - 1; x++) {
      connections.push([path[x], path[x + 1]])
    }
  }
  
  const therapies = [
    "Intubated",
    "Tracheostomy",
    "PEEP>10 cmH_2O",
    "Neuro-muscular blockade",
    "Prone positioning",
    "Inhaled nitric oxide",
    "Inhaled prostacyclin",
    "Bronchoscopy",
    "Renal replacement therapy",
    "Diuretics",
    "Corticosteroids",
    "Therapeutic heparin",
    "Remdesivir",
    "Azithromycin",
    "Hydroxychloroquine",
    "Tocilizumab",
    "Anankinra",
    "Anti-Bacterial",
    "Anti-Fungal",
    "discharged",
    "died"
  ]

  const sankey = therapies.map((therapy, index) => {
    let outTop = 0; let inTop = 0;
    return {
      name: therapy,
      index: index,
      height: paths.filter(path => path.includes(therapy)).length,
      out: therapies.filter(t => t !== therapy).map(nextTherapy => {
        const count = connections.filter(conn => conn[0] === therapy && conn[1] === nextTherapy).length;
        outTop += count;
        return {
          name: nextTherapy,
          count: count,
          index: therapies.indexOf(nextTherapy),
          top: outTop - count
        }
      }),
      in: therapies.filter(t => t !== therapy).map(prevTherapy => {
        const count = connections.filter(conn => conn[0] === prevTherapy && conn[1] === therapy).length;
        inTop += count;
        return {
          name: prevTherapy,
          count: count,
          index: therapies.indexOf(prevTherapy),
          top: inTop - count
        }
      })
    }
  })

  const BAR_WIDTH = 30;
  const BAR_SPACING = 40;
  const dischargeHeight = sankey.filter(t => t.name === "discharged")[0].height;

  return (
    <div className="m-4">
      <div className="flex justify-center flex-row flex-wrap">
        <div className="flex flex-row justify-center">
          <select className="p-4 m-4 bg-gray-100 border-3 border-gray-400 rounded-md" onChange={e => setGender(parseInt(e.target.value))}>
            <option value={0}>All Genders</option>
            <option value={1}>Female</option>
            <option value={2}>Male</option>
          </select>

          <select className="p-4 m-4 bg-gray-100 border-3 border-gray-400 rounded-md" onChange={e => setAge(parseInt(e.target.value))}>
            <option value={0}>All Ages</option>
            <option value={1}>20-30</option>
            <option value={2}>30-40</option>
            <option value={3}>40-50</option>
            <option value={4}>50-60</option>
            <option value={5}>60-70</option>
            <option value={6}>70-80</option>
          </select>
        </div>

        <div className="flex flex-row justify-center">
          <select className="p-4 m-4 bg-gray-100 border-3 border-gray-400 border-black rounded-md" onChange={e => setArds(parseInt(e.target.value))}>
            <option value={0}>All ARDS Severities</option>
            <option value={1}>Mild ARDS</option>
            <option value={2}>Moderate ARDS</option>
            <option value={3}>Severe ARDS</option>
          </select>

          <select className="p-4 m-4 bg-gray-100 border-3 border-gray-400 rounded-md" onChange={e => setOutcome(e.target.value.toString() === "0" ? null : e.target.value)}>
            <option value={0}>All Outcomes</option>
            <option value={"died"}>Died</option>
            <option value={"discharged"}>Discharged</option>
          </select>
      </div>
      </div>
        

      <div className="grid grid-cols-2 lg:grid-cols-3 p-0 sm:p-8 justify-items-start sm:justify-items-center m-auto max-w-6xl">
        <div className="my-1 sm:my-2"><label className="mr-4 text-gray-700 text-xs sm:text-md md:text-xl">Tracheostomy</label><input class="p-4 checked:bg-blue-600 checked:border-transparent" type="checkbox" checked={trach} onChange={e => setTrach(e.target.checked)} /></div>
        <div className="my-1 sm:my-2"><label className="mr-4 text-gray-700 text-xs sm:text-md md:text-xl">{"PEEP>10 cmH_2O"}</label><input class="p-4 checked:bg-blue-600 checked:border-transparent"  type="checkbox" checked={peep} onChange={e => setPeep(e.target.checked)} /></div>
        <div className="my-1 sm:my-2"><label className="mr-4 text-gray-700 text-xs sm:text-md md:text-xl">Neuro-muscular blockade</label><input class="p-4 checked:bg-blue-600 checked:border-transparent"  type="checkbox" checked={nmb} onChange={e => setNmb(e.target.checked)} /></div>
        <div className="my-1 sm:my-2"><label className="mr-4 text-gray-700 text-xs sm:text-md md:text-xl">Prone positioning</label><input class="p-4 checked:bg-blue-600 checked:border-transparent"  type="checkbox" checked={prone} onChange={e => setProne(e.target.checked)} /></div>
        <div className="my-1 sm:my-2"><label className="mr-4 text-gray-700 text-xs sm:text-md md:text-xl">Inhaled nitric oxide</label><input class="p-4 checked:bg-blue-600 checked:border-transparent"  type="checkbox" checked={nitric} onChange={e => setNitric(e.target.checked)} /></div>
        <div className="my-1 sm:my-2"><label className="mr-4 text-gray-700 text-xs sm:text-md md:text-xl">Inhaled prostacyclin</label><input class="p-4 checked:bg-blue-600 checked:border-transparent"  type="checkbox" checked={prostacyclin} onChange={e => setProstacyclin(e.target.checked)} /></div>
        <div className="my-1 sm:my-2"><label className="mr-4 text-gray-700 text-xs sm:text-md md:text-xl">Bronchoscopy</label><input class="p-4 checked:bg-blue-600 checked:border-transparent"  type="checkbox" checked={broncho} onChange={e => setBroncho(e.target.checked)} /></div>
        <div className="my-1 sm:my-2"><label className="mr-4 text-gray-700 text-xs sm:text-md md:text-xl">Renal replacement therapy</label><input type="checkbox" checked={rrt} onChange={e => setRrt(e.target.checked)} /></div>
        <div className="my-1 sm:my-2"><label className="mr-4 text-gray-700 text-xs sm:text-md md:text-xl">Diuretics</label><input type="checkbox" checked={diuretics} onChange={e => setDiuretics(e.target.checked)} /></div>
        <div className="my-1 sm:my-2"><label className="mr-4 text-gray-700 text-xs sm:text-md md:text-xl">Corticosteroids</label><input type="checkbox" checked={cortico} onChange={e => setCortico(e.target.checked)} /></div>
        <div className="my-1 sm:my-2"><label className="mr-4 text-gray-700 text-xs sm:text-md md:text-xl">Therapeutic heparin</label><input type="checkbox" checked={heparin} onChange={e => setHeparin(e.target.checked)} /></div>
        <div className="my-1 sm:my-2"><label className="mr-4 text-gray-700 text-xs sm:text-md md:text-xl">Remdesivir</label><input type="checkbox" checked={remdesivir} onChange={e => setRemdesivir(e.target.checked)} /></div>
        <div className="my-1 sm:my-2"><label className="mr-4 text-gray-700 text-xs sm:text-md md:text-xl">Azithromycin</label><input type="checkbox" checked={azithromycin} onChange={e => setAzithromycin(e.target.checked)} /></div>
        <div className="my-1 sm:my-2"><label className="mr-4 text-gray-700 text-xs sm:text-md md:text-xl">Hydroxychloroquine</label><input type="checkbox" checked={hydro} onChange={e => setHydro(e.target.checked)} /></div>
        <div className="my-1 sm:my-2"><label className="mr-4 text-gray-700 text-xs sm:text-md md:text-xl">Tocilizumab</label><input type="checkbox" checked={tocilizumab} onChange={e => setTocilizumab(e.target.checked)} /></div>
        <div className="my-1 sm:my-2"><label className="mr-4 text-gray-700 text-xs sm:text-md md:text-xl">Anankinra</label><input type="checkbox" checked={anankinra} onChange={e => setAnankinra(e.target.checked)} /></div>
        <div className="my-1 sm:my-2"><label className="mr-4 text-gray-700 text-xs sm:text-md md:text-xl">Anti-Bacterial</label><input type="checkbox" checked={antibac} onChange={e => setAntibac(e.target.checked)} /></div>
        <div className="my-1 sm:my-2"><label className="mr-4 text-gray-700 text-xs sm:text-md md:text-xl">Anti-Fungal</label><input type="checkbox" checked={antifung} onChange={e => setAntifung(e.target.checked)} /></div>
      </div>

      <br />
      {/* <div>{actualData.length} patient{actualData.length===1 ? "" : "s"}</div> */}

      {sankey.map(therapy => (
        <ReactTooltip id={therapy.name} key={therapy.name}>
          <div>{therapy.name}</div>
          <div>{therapy.height} patient{therapy.height === 1 ? "" : "s"}</div>
        </ReactTooltip>
      ))}
      {sankey.map(therapy => {
        return (
          therapy.out.map(nextTherapy => {
            const id = `path_${therapy.index}_${nextTherapy.index}`;
            return (
              <ReactTooltip id={id}>
                <span>{therapy.name} to {nextTherapy.name === "died" ? "death" : nextTherapy.name} ({nextTherapy.count} patient{"s"})</span>
              </ReactTooltip>
            )
          })
        )
      })}

      <div className="svg-container flex justify-center">
        <svg width={(therapies.length * BAR_WIDTH) + ((therapies.length - 1) * BAR_SPACING)} height="400px" preserveAspectRatio="none" viewBox={`0 0 ${(therapies.length * BAR_WIDTH) + ((therapies.length - 1) * BAR_SPACING)} ${actualData.length}`}>
          
          
          {sankey.map((therapy, index) => {
            const rectX = index * (BAR_SPACING + BAR_WIDTH);
            return (
              <React.Fragment key={index}>
                {therapy.out.map(nextTherapy => {
                  const startX = nextTherapy.index > therapy.index ? rectX + BAR_WIDTH : rectX;
                  const startTopY = nextTherapy.top;
                  const startBottomY = nextTherapy.top + nextTherapy.count;
                  const destination = sankey.filter(t => t.name === nextTherapy.name)[0].in.filter(t => t.name === therapy.name)[0];
                  const isDeath = nextTherapy.name === "died";
                  let endX = nextTherapy.index < therapy.index ? nextTherapy.index * (BAR_SPACING + BAR_WIDTH) + BAR_WIDTH : nextTherapy.index * (BAR_SPACING + BAR_WIDTH);
                  if (isDeath) endX -= (BAR_SPACING + BAR_WIDTH);
                  const endTopY = destination.top + (isDeath ? dischargeHeight : 0);
                  const endBottomY = destination.top + destination.count+ (isDeath ? dischargeHeight : 0);
                  const points = [
                    `${startX},${startTopY}`,
                    `${startX},${startBottomY}`,
                    `${endX},${endBottomY}`,
                    `${endX},${endTopY}`,
                  ]
                  const isPrev = hoveredTherapy === nextTherapy.name;
                  const isNext = hoveredTherapy === destination.name;
                  return (
                    <polygon
                      points={points.join(" ")}
                      fill={isPrev ? "#3498db" : isNext ? "#e27f45" : "#888888"} className={`arrow ${isPrev || isNext ? "active" : ""}`} 
                      data-tip data-for={`path_${therapy.index}_${nextTherapy.index}`}  
                    />
                  )
                })}
              </React.Fragment>
            )
          })}
          
          {sankey.map((therapy, index) => {
            let rectX = index * (BAR_SPACING + BAR_WIDTH);
            const isDeath = therapy.name === "died";
            if (isDeath) rectX -= (BAR_SPACING + BAR_WIDTH);
            return (
              <rect
                key={index}
                x={rectX}
                y={isDeath ? dischargeHeight : 0}
                onMouseEnter={() => setHoveredTherapy(therapy.name)}
                onMouseLeave={() => setHoveredTherapy(null)}
                width={BAR_WIDTH}
                height={therapy.height}
                className="therapy"
                fill={therapy.name === "died" ? "#da5a52" : therapy.name === "discharged" ? "#56b091" : therapy.name === "Intubated" ? "#7570b3" : "#34495e"}
                data-tip data-for={therapy.name}
                />

            )
          })}

        </svg>
      </div>

      
    </div>
  );
};

App.propTypes = {
  
};

export default App;
